<template>
  <v-app style="min-height: 100vh;"><!-- v-layout--full-height not working on beta -->
    <v-navigation-drawer
      rail
      expand-on-hover
      dark
    >
      <v-list density="compact" nav>
        <v-list-item
          :href="appUrl"
          exact
          prepend-icon="mdi-account-arrow-left"
          title="Back to Kode-hub"
        />

        <v-list-item
          v-for="(menu, index) in menuItems"
          :key="index"
          :to="menu.route"
          :prepend-icon="menu.icon"
          :title="menu.text"
          exact
        />
      </v-list>
    </v-navigation-drawer>

    <v-main style="padding: 0px 0px 0px 56px;">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  setup() {
    const menuItems = ref([
      { route: { name: 'home' }, icon: 'mdi-alpha-k-circle-outline', text: 'Dashboard' },
      { route: { name: 'users' }, icon: 'mdi-account-multiple', text: 'Users' },
      { route: { name: 'currencies' }, icon: 'mdi-currency-usd', text: 'Currencies' },
      { route: { name: 'lego' }, icon: 'mdi-toy-brick-outline', text: 'Lego' },
      { route: { name: 'lego-pieces' }, icon: 'mdi-toy-brick-outline', text: 'Lego pieces' },
    ]);

    return {
      menuItems,
      appUrl: computed(() => process.env.VUE_APP_MAIN_HOST),
    };
  },
};
</script>
